@media (max-width: 1440px) {
  .header-toggle-cstm {
    display: block;
  }
  .get-started-bg.custom_bg_homepage h3.get-started-prg {
    font-size: 42px;
  }
  .get-started-bg.custom_bg_homepage p {
    font-size: 16px;
  }
  #top-nav-header ul.get-started-links li a {
    font-size: 16px;
    font-weight: 500;
  }
  .btn-get-started {
    font-size: 18px;
  }
  .icon-counter-single.icon-counter-center {
    padding: 15px;
  }
  .modal-body-create-grp {
    max-height: 500px;
  }
  .admin-group-page .adm-group-heading {
    width: 100%;
    height: auto;
    padding: 10px !important;
  }
  .button-UI {
    flex-wrap: wrap;
  }
  .tmplt-btn {
    padding: 8px 20px;
    font-weight: 500;
    font-size: 16px;
    max-width: fit-content;
    min-width: auto;
    margin: 0;
  }
  .adm-group-slider .adm-group-slider-heading a {
    font-size: 16px;
  }
  .margin-top {
    margin-top: 15px;
  }
  .admin-tools-menu
    .adm-tools-outer-box
    .adm-tools-card
    .adm-tools-card-inner
    h5 {
    font-size: 16px;
    color: #fff;
    min-height: auto;
    margin: 0;
  }
  .community-title {
    padding: 0;
  }

  .community-title p {
    margin: 0;
    font-size: 18px;
    color: #707070;
    font-style: italic;
    width: 100%;
  }
  .moda-dialogue-custom .event-info-footer {
    max-height: 500px !important;
    overflow-x: hidden;
    height: 100%;
    overflow-y: auto;
  }
  .post {
    font-size: 16px;
  }
  .event-card.card-group-col.custom-event .event-title h4 {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .inner-container-form {
    max-width: 70%;
  }
  .tmplt-about-us .owl-carousel .owl-item img {
    height: 450px;
  }
  .icon-counter-content .counter {
    font-size: 20px;
  }
  .custom-group-member .group-member-lists .group-member-margin {
    margin-bottom: 20px;
  }
  .create-discussion-btn {
      position: relative;
      top: 0;
      right: 0;
      margin-bottom: 15px;
  }
  .settings-page .group-tab-nav.custom-nav-tabs-main nav div#nav-tab {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0;
  }
  .settings-page .group-tab-nav.custom-nav-tabs-main .nav-link {
    white-space: nowrap;
  }
  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }
  .group-tab-nav.custom-nav-tabs-main nav div#nav-tab {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0;
    white-space: nowrap;
  }
  .shared-media-post label span {
    display: none;
  }
  .shared-media-post svg {
    margin-right: 0;
  }
  .shared-media-post .create-post-icons-upld .row .col {
    flex: 0 1 !important;
  }
}

@media (max-width: 1024px) { 
  .custom-template .banner-content {
    width: 80% !important;
  }
  .custom-template .banner-content h1 {
    font-size: 48px !important;
  }
  .custom-template .banner-content p {
    font-size: 22px !important;
  }
  .custom-template .section-content h2 {
    font-size: 46px !important;
    line-height: 58px !important;
  }
  .custom-template .section-content h3 {
    font-size: 46px !important;
    line-height: 65px !important;
  }
  .custom-template .section-content p {
    font-size: 20px !important;
  }
  .youtube-video .pattern-image-1 {
    top: -35px;
    right: -40px;
    height: 80px;
  }
  .youtube-video .pattern-image-2 {
    left: 10px;
    height: 100px;
  }
  .youtube-video .pattern-image-3 {
    bottom: -30px;
    right: -35px;
    height: 120px;
  }
  .youtube-video .pattern-shape-1 {
    top: -35px;
    right: -40px;
    width: 80px;
    height: 80px;
    border-radius: 0 80px 0 0;
    -moz-border-radius: 0 80px 0 0;
    -webkit-border-radius: 0 80px 0 0;
  }
  .quick-join-box .pattern-image-1,
  .quick-join-box .pattern-image-2,
  .quick-join-box .pattern-image-3,
  .quick-join-box .pattern-image-7,
  .quick-join-box .pattern-image-8 {
    display: none;
  }
  .me-80 {
    margin-right: 40px;
  }
  .why-choose-image .pattern-image {
    position: absolute;
    top: -30px;
    left: -30px;
  }
  .why-choose-image .pattern-shape {
    position: absolute;
    bottom: -35px;
    right: -40px;
  }
  .contact-form .shape {
    width: 60px;
    height: 60px;
  }
  .contact-us-section .box-1 {
    height: 45px;
    width: 45px;
  }
  .contact-us-section .box-2 {
    height: 32px;
    width: 32px;
  }
  .contact-us-shape-top .box-2 {
    bottom: -32px;
  }
  .contact-us-shape-bottom .box-1, .contact-us-shape-bottom .box-3 {
    top: -32px;
  }
  .contact-us-section .rounded-border {
    width: 200px;
  }
  .custom-template.advanced-template .banner-content br,
  .custom-template.advanced-template .section-content br {
    display: none;
  }
  .custom-template.advanced-template .banner-content h1 { 
    line-height: 70px !important;
  }
  .custom-template.advanced-template .section-content h3 {
    font-size: 38px !important;
  }
  .custom-template.advanced-template h2 {
    font-size: 42px !important;
  }
  .common-tabs {
    margin: 0;
    padding-left: 0;
  }
  .my-profile-page {
    margin-top: 5px;
  }
  .my-profile-card .profile-card .common-tabs {
    padding-left: 0;
  }
  .community-card .card-header {
    margin-bottom: 0 !important;
  }
  .group-tab-nav.custom-nav-tabs-main ~ .tab-content {
    padding: 15px;
  }
  .group-tab-nav.custom-nav-tabs-main .nav-link {
    font-size: 15px;
    margin: 0 20px;
  }
  .event-card-body {
    padding-top: 20px;
  }
  .ui-design-gallery {
    padding: 20px 0 0;
  }
  .media-posts-card-image img {
    max-height: 250px;
  }
  .view-group-detail-about.cusom-group-design.bg-white h3 {
        font-size: 20px;
  }
  .Profile-picture-group .profile-image {
    height: 140px;
    width: 140px;
  }
  .Profile-picture-group .group-content {
    margin-left: 10px !important;
  }
  .group-content img {
    height: 32px;
    width: 33px;
    min-width: 32px;
    min-height: 32px;
  }
  .group-member-page-outer .group-member-lists .box a img {
    width: 36px;
    height: 36px;
  }
  .custom-group-member .group-member-lists .box {
    min-height: auto;
    padding: 12px;
  }
  .group-member-page-outer .group-member-lists .box h6 {
    font-size: 0.875rem;
  }
  .custom-group-member .group-member-lists .group-member-margin {
    margin-bottom: 16px;
  }
  .account-settings {
    font-size: 0.875rem;
  }
  .account-settings h1 {
    font-size: 1rem;
  }
  .account-settings .privacy-card-outer h5 {
    font-size: 1rem;
  }
  .account-settings .privacy-card-outer .privacy-card-public p {
    font-size: 0.813rem;
  }
  .account-settings .privacy-card-outer {
    padding: 13px;
  }
  .account-settings .profile-image {
    height: 80px;
    width: 80px;
  }
  .custom-accordion.group-accordion.calendar-accordion .accordion-header {
    width: 87%;
  }
  .horizontal-tabs .nav-container h4 {
    font-size: 18px;
  }
  .w-50p {
    width: 75% !important;
  }
  .common-card h1 {
    font-size: 18px;
  }
  .plan-payment-body .plan-payment-amount p,
  .upgrade-plan-page .main-heading,
  .plan-payment-body .plan-payment-amount h4 {
    font-size: 16px;
    white-space: nowrap;
  }
  .custom-signup-form {
    padding: 20px;
  }
  .max-w-75 {
    max-width: 100% !important;
  }
  .get-started-bg.custom_bg_homepage p {
    max-width: 100% !important;
  }
  .ui-design-gallery .group-img img {
    height: 175px;
  }
  .video-play {
    height: 175px;
  }
  .group-cover-img {
    height: auto;
  }
  .group-cover-img img {
    height: 200px;
    object-fit: cover;
  }
  #top-nav-header.header-new a.navbar-brand img {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media (max-width: 991px) {
  #top-admin-header {
    padding: 0.45rem 0;
  }
  #top-nav-header a.navbar-brand img {
    width: 120px;
  }
  .bg_header_new_homepge-new .main-outer-container {
    padding-top: 0;
  }
  .plan-payment-body .plan-payment-amount h4 {
    width: 100%;
    white-space: nowrap;
  }
  .signup-payment-page .custom-signup-form {
    margin-top: 20px;
  }
  .left-sidebar .sidebar-inner ul {
    margin: 0;
    max-height: calc(100vh - 311px);
  }
  .custom-card {
    min-height: calc(100vh - 150px);
  }
  .custom-card-design.custom-card {
    min-height: auto;
  }
  .community-card .main-outer-container {
    padding-top: 0;
  }
  .media-posts-card-header .media-posts-card-profile .media-posts-card-profile-pic img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .media-posts-card .media-posts-card-header {
    padding: 16px 16px 12px;
  }
  .media-posts-card .media-posts-card-content {
    font-size: 14px;
    padding: 5px 20px 12px;
  }
  .like-cmnt-counting .cmnt-count,
  .like-cmnt-counting .likes-count {
    font-size: 14px !important;
  }
  .media-posts-card .media-posts-card-header .media-posts-card-profile-name p {
    font-size: 16px;
    margin-bottom: 0;
  }
  .media-posts-card .media-posts-card-header .media-posts-card-profile-name span {
    font-size: 12px;
  }
  .add-like-comment {
    font-size: 14px;
    padding: 12px 0 12px;
  }
  .like-cmnt-countingcustom {
    padding: 8px 0 8px;
  }
  .like-cmnt-counting .likes-count svg {
    height: 28px;
    width: 28px;
  }
  .media-posts-card .media-posts-card-header {
    padding: 16px 16px 10px;
  }
  .media-posts-create-cmnt {
    padding: 16px 0px 16px;
  }
  .media-posts-create-cmnt img.commentprofile2 {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-top: 2px;
  }
  .add-like-comment svg {
    height: 16px;
    width: 16px;
  }
  .modal .media-group-box .group-img img {
    height: 100px;
  }
  .create-post-outer-box .create-post-inner-box .create-post label.with-spin {
    flex-direction: row;
    max-width: 60px;
  }
  .common-modal .create-post-icons-upld .icon {
    margin-right: 0 !important;
  }
  .common-modal .thumbnail-image-post-img img, .common-modal .thumbnail-video-post-img iframe, .common-modal .thumbnail-video-post-img video {
    height: 100px;
  }
  .create-post-outer-box .create-post-inner-box .create-post label span {
    font-size: 12px;
    margin-top: 8px;
    white-space: nowrap;
  }
  .docfile .doc-icon {
    font-size: 26px;
  }
  .comment-details span a.text-primary-theme {
    font-size: 14px;
  }
  .create-post-outer-box .create-post-inner-box .create-post {
    flex-direction: column;
  }
  .modal.discussions-container .media-posts-create-cmnt.inner-content, .modal.discussions-container .media-posts-create-cmnt1.inner-content {
    max-width: 90%;
  }
  .modal.discussions-container .modal-header {
    padding: 0.875rem 1rem 0.875rem !important;
  }
  .modal.discussions-container .media-posts-card-content img {
    max-height: 110px;
    display: block;
  }
  .modal.discussions-container .group-img {
    margin-bottom: 0 !important;
  }
  .user-edit-profile-page .create-group-upld-img.Custom-profile-image {
    max-width: 120px;
    height: 120px;
    margin: 0 0 20px;
  }
  .user-edit-profile-page.group-page .modal-create-group-upld-img .create-group-upld-img label,
  .user-edit-profile-page .modal-create-group-upld-img .create-group-upld-img label img {
    height: 120px !important;
  }
  .user-edit-profile-page.group-page .modal-create-group-upld-img .create-group-upld-img label.image-edit-icon {
    height: 34px !important;
  }
  .group-page .form-control, .group-page .form-select {
    font-size: 0.938rem;
    padding: .5rem 1rem;
  }
  .account-settings .tab-content .custom-card {
    min-height: calc(100vh - 395px);
  }
  .edit-template-header #top-admin-header button.navbar-toggler i {
    color: #fff;
  }
  .award-image img {
    height: 120px !important;
  }
  .congratulation-box .cong-heading {
    font-size: 28px;
  }
  .congratulation-box h3 {
    font-size: 22px;
  }
  .congratulation-box p {
    font-size: 16px;
  }
  .let-get-rock-btn {
    font-size: 16px;
  }
  .upgrade-plan-page .main-outer-container,
  .upgrade-plan-page .dashboard-outer-container,
  .upgrade-plan .main-outer-container {
    padding-top: 0 !important;
  }
  .upgrade-plan .main-heading {
    margin-top: 0 !important;
  }
  .upgrade-plan #msform {
    margin-top: 30px !important;
  }
  .upgrade-plan .custom-signup-form {
    padding: 10px 0;
  }
  .upgrade-plan .field-set-form {
    margin-bottom: 10px !important;
  }
  .group-page .small-profile-image svg {
    height: 40px;
  }
  main.login-bg.Create-profile {
    align-items: center;
  }
  .custom-template section.template-tabs-section {
    padding: 0 !important;
  }
  .view-group-detail-about.cusom-group-design.bg-white {
    padding: 20px 20px 20px;
  }
  .Profile-picture-group .profile-image {
    margin-top: -45px;
  }
  .custom-card-design .flex--about .areatext h3 {
    font-size: 16px;
  }
  .custom-card-design .flex--about .areatext p {
    font-size: 15px;
  }
  .create-post-outer-box .create-post-inner-box .create-post {
    flex-direction: row !important;
  }
  .discussion-modal .docfile {
    height: 100px;
  }
  .create-post-text .react-input-emoji--container {
    min-height: auto;
  }
  .create-post-text .react-input-emoji--input {
    padding: 10px 14px 10px 16px !important;
    font-size: 14px !important;
  }
  .react-input-emoji--button {
    right: 4px !important;
  }
  .react-input-emoji--button svg {
    height: 20px;
    width: 20px;
  }
  .create-post-text .react-input-emoji--placeholder {
    font-size: 14px;
    left: 16px;
  }
  .create-post-outer-box .create-post-inner-box .create-post label span {
    display: block;
  }
  .create-post-outer-box .create-post-inner-box .create-post label svg {
    margin-right: 0 !important;
  }
  .modal.discussions-container .thumbnail-image-post-img.document {
    height: 100px;
  }
  .modal-create-group-upld-img .create-group-upld-img label {
    height: 200px;
  }
  .create-post-icons-upld .row .col {
    flex: 0 !important;
  }
  .create-post-inner-box .create-post-text img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .left-sidebar .sidebar-inner ul.change-plan-btn {
    max-height: calc(100vh - 330px);
  }
  .w-25 {
    width: 33% !important;
  }
  .modal-emoji textarea {
    line-height: 14px !important;
    padding: 15px 50px 0 16px !important;
  }
  .modal-emoji .btn-primary {
    top: 18px !important;
    right: 4px;
  }
  .modal-emoji .btn-primary svg {
    height: 20px;
    width: 20px;
  }
  .media-posts-create-cmnt1.inner-content img.commentprofile {
    height: 40px;
    width: 40px;
  }
  .modal.discussions-container .media-group-box.docfile,
  .modal.discussions-container .video-play,
  .modal .media-group-box .group-img img {
    height: 110px;
  }
  .modal .small-profile-image .camera-icon {
    height: 32px;
  }
  .modal .small-profile-image-box {
    height: 142px !important;
    width: 142px !important;
  }
  .modal .small-profile-image {
    height: 140px !important;
    width: 140px !important;
  }
  .user-profile {
    white-space: nowrap;
  }
  .user-profile .user-img {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
  .create-community-page .main-heading {
    font-size: 21px;
  }
  .create-community-page .inner-container-form {
    padding: 0;
  }
  .advanced-template .navbar-collapse .navbar-nav .btn-link {
    border: 1px solid #333 !important;
    color: #333 !important;
  }
  .upgrade-plan-page .get-started-bg-new {
    min-Height: auto;
    margin-top: 0 !important;
  }
  .custom-ui-header {
    padding: 15px 20px;
  }
  #top-nav-header.custom-ui-header ul.get-started-links li {
      margin: 0 14px;
  }
  .message-history {
    height: calc(100vh - 500px);
  }
  .full-calendar .fc .fc-toolbar-title {
    font-size: 1.125rem;
  }
  .theme-header-2 ul {
    margin: 15px 0 !important;
  }
  .media-file-card .MuiSvgIcon-root {
    font-size: 32px !important;
  }
  .media-search-container {
    position: relative;
    top: 15px;
    margin-bottom: 10px;
  }
  .add-like-comment .like {
    margin-right: 25px;
  }
  .thumbnail-image-post-img img,
  .thumbnail-video-post-img iframe,
  .media-posts-outer-box .thumbnail-preview.document,
  .support-page .docfile,
  .thumbnail-preview.document {
    height: 110px;
  }
  div.custom-template.new-advanced-template .banner-content h1 {
    line-height: 50px !important;
  }
  div.custom-template.new-advanced-template .banner-content p {
    line-height: 32px !important;
  }
  .new-advanced-template .btn {
    font-size: 16px;
    padding: 0.625rem 1.25rem;
    height: 40px;
    line-height: 22px;
  }
  .new-advanced-template .btn.with-icon {
    padding: 0.8rem 1.25rem 0.625rem !important;
    height: 38px;
    line-height: 21px;
  }
  .new-advanced-template .btn-primary svg {
    height: 16px;
    max-width: 25px;
    top: -1px !important;
  }
  div.new-advanced-template .navbar-collapse .navbar-nav .btn {
    padding: 0.4rem 0.8rem 0.45rem;
    max-width: 125px;
    line-height: 28px;
  }
  .custom-template.new-advanced-template .section-content h3 {
    padding-bottom: 20px !important;
    margin-bottom: 15px !important;
    line-height: 40px !important;
  }
  .new-advanced-template .common-section img {
    max-height: 280px;
  }
  .new-advanced-template .group-section-template h2,
  .new-advanced-template .fitness-gallery-section h2 {
    margin-bottom: 20px !important;
    line-height: 44px !important;
  }
  .custom-template.new-advanced-template .contact-us-section .section-content h3 {
    padding-bottom: 0 !important;
  }
  div.new-advanced-template .fitness-gallery-section .images img {
    height: 260px !important;
  }
  .simple-modal .live-stream-video,
  .simple-modal .live-stream-video iframe,
  .simple-modal .live-stream-video div,
  .simple-modal .live-stream-video video {
    height: 300px !important;
  }
  .simple-modal div.live-video-image-count,
  .simple-modal div.live-video-image-count div {
    height: auto !important;
  }
  .simple-modal .message-history {
    height: 180px;
  }
  .live-stream-image img {
    height: 300px;
  }
  .modal .countdown-timer {
    font-size: 12px;
  }
  .countdown-timer .live-stream-icon {
      height: 22px;
      width: 22px;
      margin-right: 10px;
  }
  .upcoming-video {
    min-height: 250px;
    height: auto !important;
  }
}
@media (max-width: 992px) {
  .bg_header_new_homepge {
    height: 100%;
    overflow: visible;
  }
  .abt-text p {
    font-size: 16px;
    color: #707070;
    font-weight: 300;
    line-height: 27px;
  }
  .form-box {
    padding: 30px;
    margin-bottom: 25px;
  }
  /* Get Started */
  .get-started-bg.custom_bg_homepage h3.get-started-prg {
    font-size: 32px;
  }
  /* #progressbar li span {
    display: none;
  } */
  #top-nav-header ul.get-started-links li a {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    padding: 10px 0px;
  }
  .get-started-bg.custom_bg_homepage p {
    font-size: 16px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 6; */
  }

  /* Get Started ends here */
  .login-bg {
    flex-direction: column;
    justify-content: start;
    gap: 25px;
  }
  .bg-main-login {
    width: 100%;
    height: 250px;
    background-position: center;
  }
  .Login-form {
    width: 100%;
  }
  .inner-container-form {
    max-width: 100%;
    padding: 0;
  }
  .button-toogle {
    display: block;
    background: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    /* padding: 5px 13px; */
    width: 40px;
    height: 40px;
    text-align: center;
  }
  .mar-nav-left-0 {
    left: 0px !important;
  }
  .button-toogle i.fa.fa-bars {
    font-size: 18px;
  }

  .view-all-adm-group-cards .view-all-adm-group-container .owl-cstm-item {
    width: initial;
  }

  .left-sidebar {
    left: -300px;
    top: 55px;
    border-radius: 0;
    position: fixed;
    width: 250px;
    margin: 0;
  }
  .dashboard-container.active .left-sidebar {
    left: 0px;
  }
  .right-sidebar {
    width: 100%;
    margin-left: 0px;
    padding: 0;
  }

  .get-started-bg.custom_bg_homepage h3.get-started-prg small {
    display: block;
    font-size: 22px;
    width: auto;
    max-width: 300px;
    margin: 0 auto;
    font-style: italic;
    font-weight: 400;
    padding: 5px;
  }
  .get-in-touch__img {
    position: relative;
    display: block;
    margin-right: 0;
    z-index: 5;
    margin-bottom: 20px;
  }
  .get-in-touch__img img {
    width: 100%;
  }
  .form-addess {
    margin-bottom: 20px;
  }
  .section-title__title {
    font-size: 32px;
  }
  .moda-dialogue-custom {
    min-width: 100%;
  }
  .adm-group-slider .adm-group-slider-heading h3 {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .admin-group-page .adm-group-heading h3 a i,
  .admin-tools-menu-heading h3 a i {
    width: 20px;
    height: 20px;
    font-size: 18px;
  }
  .rolespermission-inner-box table thead tr th {
    padding: 16px;
    white-space: nowrap;
  }
  .banner-section {
    min-height: 500px;
  }
  .custom-template h1 {
    font-size: 38px !important;
    margin-bottom: 15px !important;
  }
  .custom-template p {
    font-size: 18px !important;
  }
  .custom-template .banner-content {
    width: 90% !important;
    padding: 0 15px;
  }
  .youtube-video .pattern-image-1,
  .youtube-video .pattern-image-2,
  .youtube-video .pattern-image-3,
  .youtube-video .pattern-shape-1,
  .youtube-video .pattern-shape-2,
  .why-choose-image .pattern-image,
  .why-choose-image .pattern-shape,
  .contact-us-section img,
  .contact-form img,
  .contact-form .shape,
  .contact-form .corner-shape,
  .contact-us-section .rounded-border,
  .contact-us-shape-top,
  .contact-us-shape-bottom {
    display: none;
  }
  .custom-template .banner-content p {
    font-size: 18px !important;
    margin-bottom: 20px !important;
  }
  .custom-template .banner-content h1 {
    font-size: 44px !important;
  }
  .custom-template section {
    padding: 50px 0 !important;
  }
  .custom-template .section-content h2 {
    font-size: 38px !important;
    line-height: 52px !important;
    margin-bottom: 20px !important;
  }
  .custom-template .section-content p {
    margin-bottom: 20px !important;
  }
  .custom-template .navbar-toggler-icon {
    filter: brightness(0) invert(1);
  }
  .me-80 {
    margin-right: 0 !important;
  }
  .custom-template h2 {
    font-size: 40px !important;
    line-height: 54px !important;
  }
  .group-section-template {
    padding-bottom: 50px !important;
  }
  .group-image img {
    height: 200px !important;
  }
  .contact-form {
    padding: 35px;
    margin-top: 40px !important;
  }
  .custom-template .edit-btn i {
      font-size: 14px;
  }
  .custom-template .section-content h3 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .why-choose-image .main-image {
    height: 350px !important;
  }
  .youtube-video iframe,
  .youtube-video video {
    height: 350px !important;
  }
  .advanced-template.custom-template .banner-content {
    width: 100% !important;
  }
  .custom-template.advanced-template .banner-content h1 {
    line-height: 55px !important;
  }
  .advanced-template .banner-section {
    min-height: 450px;
  }
  .advanced-template .template-tabs .nav-link .tab-small-title { 
    font-size: 15px !important;
  }
  .advanced-template .template-tabs .nav-link .tab-title { 
    font-size: 20px !important;
  }
  .advanced-template .template-tabs .nav-link {
    padding: 1.5rem 1.5rem;
  }
  .advanced-template .tab-content img {
    height: 300px !important;
  }
  .advanced-template .fitness-gallery-section .images img {
    height: 300px !important;
    border-radius: 10px;
  }
  .custom-template.advanced-template .section-content h3 {
    font-size: 32px !important;
  }
  .custom-template.advanced-template h2 {
    font-size: 38px !important;
  }
  .advanced-template .contact-form {
    margin-top: 20px !important;
  }
  .advanced-template .contact-form {
    padding: 25px;
  }
  .advanced-template .contact-form img { 
    display: block;
  }
  .advanced-template .btn img { 
    height: 10px;
  }
  .advanced-template .navbar-collapse .navbar-nav {
    background-color: #fff;
    padding: 10px 0px 15px;
    border-radius: 4px;
  }
  .advanced-template .navbar-collapse .navbar-nav .nav-link {
    color: #333333 !important;
    padding: 0.5rem 1rem;
    font-size: 16px;
  }
  .advanced-template .navbar-collapse .navbar-nav .btn {
    font-size: 15px;
    border: 1px solid #333;
    padding: 0.4rem 1rem;
    width: auto;
    color: #fff;
    max-width: 120px;
    margin-top: 7px;
  }
  .new-advanced-template .navbar-collapse .navbar-nav .btn {
    color: #fff;
  }
  .dashboard-outer-container {
    padding: 75px 5px 68px;
  }
  #top-admin-header .navbar-brand {
    font-size: 20px;
    color: #047ED6;
  }
  .button-toogle {
    width: 35px;
    height: 35px;
    line-height: 33px;
  }
}
@media (max-width: 767px) {
  .advanced-template .nav-fill .nav-item, .advanced-template .nav-fill>.nav-link {
    width: 100%;
  }
  .advanced-template .template-tabs {
    padding-left: 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    position: sticky;
    top: 0;
    z-index: 9;
    margin-left: -13px;
    margin-right: -13px;
  }
  .edit-template-header + .advanced-template .template-tabs  {
    top: 60px;
  }
  .advanced-template .template-tabs .nav-item:first-child .nav-link {
    padding-left: 1rem !important;
  }
  .advanced-template .template-tabs .nav-link {
    padding: 1rem !important;
    white-space: normal;
    width: 225px;
  }
  .advanced-template .template-tabs .nav-link.active {
    border-bottom: 6px solid #ED6F03;
  }
  .advanced-template .template-tabs .nav-link .tab-title {
    font-size: 18px !important;
  }
  .advanced-template .template-tabs .nav-link .tab-small-title {
    font-size: 13px !important;
  }
  .tmplt-footer {
    padding: 7px 8px;
    font-size: 11px;
  }
  .adm-profile-drop a .admin-profile-pic-name {
    display: none;
  }
  .admin-profile-pic img {
    margin-right: 0 !important;
  }
  #top-admin-header {
    padding: 0.282rem 0;
  }
  #top-admin-header .nav-item:last-child {
    margin-left: 10px;
  }
  .adm-header-nav .nav-upgrade-plan-btn {
    font-size: 10px;
    margin-right: 0 !important;
  }
  #top-admin-header .navbar-brand {
    font-size: 18px;
    margin: 0 10px;
  }
  .button-toogle {
    width: 30px;
    height: 30px;
    line-height: 27px;
  }
  .button-toogle i.fa.fa-bars {
    font-size: 16px;
  }
  .adm-header-nav li.adm-notification a.dropdown-toggle .adm-notif-bell-numb {
    top: 2px;
    right: -6px;
    width: 18px;
    height: 18px;
    font-size: 11px;
  }
  .custom-card h1 {
    font-size: 16px;
  }
  .all-sites-template-container {
    padding: 20px 0px 0px;
  }
  .custom-card .card-header .btn-primary-theme.btn-outline {
    font-size: 12px;
    padding: 0.422rem 0.875rem;
  }
  .common-tabs .nav-link {
    padding: 0.875rem 1.5rem;
  }
  .site-template-card-publish button.post {
    padding: 3px 12px !important;
  }
  .site-template-card-outer .site-template-card-name h5,
  .custom-accordion .profile h5 {
    font-size: 15px !important;
    margin-bottom: 0;
  }
  .custom-accordion .profile h5 {
    font-size: 14px !important;
  }
  .custom-card .btn-link.dropdown-toggle {
    font-size: 12px;
  }
  .custom-card .card-body {
    padding: 1rem;
  }
  .edit-site-tmplt > a,
  .edit-site-tmplt .copy-icon {
    height: 32px;
    width: 32px;
    line-height: 30px;
  }
  .edit-site-tmplt > a svg,
  .edit-site-tmplt .copy-icon svg {
    height: 14px;
  }
  .copy-link-btn,
  .eye-btn {
    height: 42px;
    width: 42px;
  }
  .copy-link-btn svg,
  .copy-link-btn img,
  .eye-btn svg,
  .eye-btn img {
    height: 14px;
  }
  .custom-accordion .table {
    white-space: nowrap;
  }
  .small-btn {
    height: 32px;
    width: 32px;
  }
  .small-btn svg,
  .small-btn img{
    height: 14px;
  }
  .table-actions .small-btn:not(:last-child) {
    margin-right: 6px;
  }
  .custom-accordion .table .user-image {
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }
  .group-content,
  .table-actions .small-btn:first-child {
    margin-left: 15px;
  }
  .custom-accordion .accordion-button {
    padding: 1rem;
    font-size: 0.938rem;
  }
  .custom-accordion .accordion-body {
    padding: 0 1rem 0.375rem;
    font-size: 0.875rem;
    line-height: 26px;
  }
  .custom-accordion .accordion-button::after {
    width: 0.75rem;
    height: 0.75rem;
    background-size: 0.75rem;
    margin-left: 8px;
  }
  .left-sidebar .sidebar-inner ul li a .icon svg {
    height: 17px;
    width: 17px;
  }
  .site-template-card-outer .site-template-card-image img:not(.copy-link-image, .edit-icon-image) {
    height: 150px !important;
  }
  .custom-signup-form {
    padding: 20px;
  }
  .custom-signup-form .text-end .next.btn.next-button,
  .custom-signup-form .text-end .btn.submit.next-button,
  .custom-signup-form .text-end .previous.btn.previous-button-border,
  .signup-payment-page .plan-payment-form .btn-primary-theme.btn-outline {
    font-size: 15px;
    max-width: fit-content;
    height: 42px;
    min-width: auto;
    width: auto;
  }
  .bg_header_new_homepge-new .main-heading {
    font-size: 20px;
  }
  .field-set-form .form-control,
  .field-set-form .cstm-field-dropdown select {
    height: 44px;
    font-size: 0.963rem;
  }
  .right-sidebar {
    padding: 0;
  }
  .tools-box {
    padding: 1.125rem 0.375rem;
  }
  .tools-icon {
      margin-bottom: 14px;
  }
  .tools-box h5 {
    font-size: 13px;
  }
  .tools-icon svg {
    height: 18px;
    width: 18px;
  }
  .group-dropdown-menu ul {
    max-height: 200px;
  }
  .common-modal .modal-title {
    font-size: 16px;
  }
  .small-profile-image,
  .modal .small-profile-image {
    height: 100px !important;
    width: 100px !important;
    border: 3px solid #fff;
  }
  .small-profile-image-box,
  .modal .small-profile-image-box {
    height: 102px !important;
    width: 102px !important;
  }
  .inner-tabs .tab-content .form-label, .common-fields .form-label {
    font-size: 13px !important;
  }
  .common-modal .modal-header .btn-link svg {
    height: 17px;
    width: 12px;
  }
  .form-text {
    font-size: .813em;
  }
  .group-page .modal-create-group-upld-img .create-group-upld-img label,
  .group-page .modal-create-group-upld-img .create-group-upld-img label img {
    height: 150px;
    background-size: cover;
    object-fit: cover;
    border-radius: 5px;
  }
  .small-profile-container {
    margin: -70px 0 15px 15px;
  }
  .custom-has-icon {
    min-width: auto;
  }
  .table,
  .table.table-bordered {
    white-space: nowrap;
  }
  .custom-card .card-footer {
    padding: 1rem 0 0;
    margin-top: 1rem !important;
  }
  .ui-design-gallery .group-img img {
      height: 120px;
  }
  .congratulation-box .cong-heading {
      font-size: 24px;
  }
  main.login-bg.Create-profile {
    margin: 0 15px;
  }
  main.login-bg.Create-profile .login-main-heading h3 {
    font-size: 22px;
  }
  .login-bg.Create-profile .login-main-heading img {
    width: 100px;
  }
  .invited-buttons .btn-group {
    margin-bottom: 8px;
  }
  .create-post-icons-upld .row {
    width: 100%;
    flex: 1 1 auto;
  }
}

@media (max-width: 768px) {
  .main-heading {
    font-size: 22px;
  }
  .group-main-container {
    padding: 30px 0px;
  }
  .heading-main p {
    font-size: 18px;
  }

  /* #progressbar li:before {
    width: 50px;
    height: 50px;
  } */

  /* #progressbar li:after {
    top: 23px;
  } */

  .curve-up {
    width: 100%;
    min-height: 200px;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
  }

  .community-url-field .cstm-url-field {
    padding: 0 10px;
    font-size: 14px;
  }

  .community-url-field .cstm-url-field-1 {
    padding: 0 10px;
    font-size: 14px;
  }
  .cstm-search {
    margin-top: 10px;
  }
  .adm-header-nav li.adm-profile-drop ul {
    position: absolute;
  }
  .adm-header-nav li.adm-notification ul {
    position: absolute;
  }
  .all-sites-tmpt-header-search {
    display: flex;
    align-items: flex-start;
    justify-content: initial;
    flex-direction: column;
  }

  .setting-progress-box > img {
    width: 120px;
    margin: 30px 0 5px;
  }

  .inner-container-template {
    margin: 0px 0px 0px;
  }

  .templateherobanner-bg .templateherobanner-bg-content {
    width: 95%;
  }

  .templateherobanner .templateherobanner-content {
    width: 95%;
  }

  .tmplt-about-us-heading {
    width: 95%;
  }

  #top-template-nav-header a.navbar-brand img {
    width: 75px;
  }

  .main-outer-container {
    padding-top: 70px;
  }

  .admin-group-page .adm-group-heading {
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ReactModal__Content {
    width: 98%;
    max-width: 600px;
  }

  .ReactModal__Content
    .modal-body
    .menu-title-edit-section
    .upload-menu-button {
    flex-direction: column;
    align-items: flex-start;
  }
  .ReactModal__Content
    .modal-body
    .menu-title-edit-section
    .upload-menu-button
    .upload-menu-button-add-list
    input[type="text"] {
    width: 100%;
  }

  .ReactModal__Content
    .modal-body
    .menu-title-edit-section
    .upload-menu-button
    .upload-menu-button-add-list {
    width: 100%;
  }
  .custom-template h1 {
    font-size: 32px !important;
  }
  .custom-template p {
    font-size: 16px !important;
    line-height: normal;
  }
  .banner-section {
      min-height: 350px;
  }
  .why-choose-image .main-image {
    height: 200px !important;
  }
  .custom-template.advanced-template .banner-content h1 {
    font-size: 32px !important;
    line-height: 45px !important;
  }
  .custom-template.advanced-template .btn-primary {
    font-size: 14px !important;
  }
  .custom-template .banner-content p {
    margin-bottom: 0 !important;
  }
  .custom-template.advanced-template .banner-content p { 
    margin-bottom: 0 !important;
  }
  .custom-template .banner-content .btn-primary {
    margin-top: 20px;
  }
  .custom-template.advanced-template .section-content h2 {
    font-size: 32px !important;
    line-height: 45px !important;
    margin-bottom: 15px !important;
  }
  .custom-template.advanced-template .section-content p {
    line-height: 28px !important;
  }
  .custom-template.advanced-template h2 {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  .advanced-template .group-section-template .section-title,
  .advanced-template .fitness-gallery-section .section-title {
    margin-bottom: 30px !important;
  }
  .custom-template.advanced-template .banner-content {
    padding: 0 !important;
  }
  .custom-template.advanced-template .section-content h3 {
    font-size: 28px !important;
  }
  .advanced-template .contact-form {
    padding: 25px;
  }
  .advanced-template .contact-form .btn {
    min-width: 125px;
  }
  .advanced-template .navbar {
    padding: 1rem 0;
  }
  .advanced-template .navbar img {
    height: 40px;
  }
  .advanced-template .logo-text {
    font-size: 1.2rem;
  }
  .dashboard-outer-container {
    padding: 5px 0 68px;
  }
  .signup-payment-page .congratulation-box {
    margin-top: 0 !important;
  }
}

@media (max-width: 576px) {
  .custom-group-member .group-member-lists .group-member-margin {
    margin-bottom: 15px;
  }
  .get-started-bg.custom_bg_homepage p {
    font-size: 15px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 5; */
  }
  .theme-header-2 nav {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px !important;
    align-items: center;
  }

  .theme-header-2 ul {
    margin: 10px 0 10px !important;
  }
  .skip-template-btn {
    font-size: 16px;
    padding: 8px 10px;
  }

  .home-banner-image {
    height: 321px;
    background-size: cover;
  }
  .cstm-search .searchTerm {
    width: 100%;
  }
  .cstm-search {
    width: 100%;
  }
  .btn-get-started {
    margin-bottom: 10px;
  }

  .main-inner-container .get-started-prg {
    font-size: 18px;
    margin-top: 25px;
    margin: 35px 10px 30px;
  }
  .bg_header_new_homepge-new .main-inner-container .get-started-prg {
      margin: 20px 0px 25px;
  }
  .header-new a.navbar-brand {
    margin-top: 0;
    margin-left: 0;
  }
  .bg_header_new_homepge-new .get-started-bg.custom_bg_homepage h3.get-started-prg {
    font-size: 36px;
  }

  .main-sub-heading {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .main-outer-container {
    padding-top: 60px;
  }

  .setting-progress-box h3 {
    font-size: 18px;
    margin: 20px 10px;
  }

  .setting-progress-box > img {
    width: 110px;
    margin: 10px 0 5px;
  }

  #progressbar {
    margin: 0 auto 20px;
    width: 100%;
  }

  #top-nav-header a.navbar-brand img {
    width: 110px;
  }

  .all-sites-tmpt-header-search {
    padding: 0 10px;
  }
  .admin-group-page .adm-group-heading {
    padding: 0 20px;
  }

  #msform fieldset {
    padding: 20px 0 0;
  }

  .community-plan-accordion
    .card
    .card-header
    .custom-control
    .plan-card-header
    .custom-control-label
    sup {
    font-size: 16px;
    top: -15px;
  }

  .community-plan-accordion
    .card
    .card-header
    .custom-control
    .plan-card-header
    .custom-control-label
    span {
    font-size: 16px;
  }

  .plan-payment {
    padding: 20px 10px 30px;
    margin-top: 20px;
  }

  .view-all-adm-group-cards {
    padding: 20px 0px 20px;
  }

  .tmplt-about-us .carousel-container .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
  }

  .tmplt-about-us .carousel-container .owl-carousel {
    padding: 0 10px;
  }

  .tmplt-about-us .owl-carousel .owl-stage-outer {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .header-toggle-cstm {
    margin-left: 10px;
  }

  .tmplt-about-us .owl-carousel .owl-item img {
    height: 304px;
  }

  .setting-progress-box .system-gif img {
    width: 120px;
    margin: 30px 0 10px;
  }

  .view-all-adm-group-cards .view-all-adm-group-container .owl-cstm-item {
    width: 100%;
  }

  .tmplt-about-us .carousel-container .owl-theme .owl-dots .owl-dot span {
    margin: 5px 4px;
  }

  .templateherobanner .templateherobanner-content {
    padding: 40px 0;
  }

  .templateherobanner-bg .templateherobanner-bg-content h4 {
    font-size: 16px;
    font-weight: 400;
  }
  .singp-link {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    position: static;
    gap: 10px;
  }
  .templateherobanner-bg .templateherobanner-bg-content h3 {
    font-size: 24px;
  }

  .templateherobanner .templateherobanner-content h4 {
    font-size: 16px;
    font-weight: 400;
  }

  .templateherobanner .templateherobanner-content h3 {
    font-size: 24px;
  }

  .tmplt-about-us .tmplt-about-us-heading h4 {
    font-size: 16px;
    font-weight: 400;
  }

  .tmplt-about-us .tmplt-about-us-heading h3 {
    font-size: 24px;
  }

  .tmplt-community-group h3 {
    font-size: 24px;
  }

  .tmplt-about-us {
    text-align: center;
    padding: 40px 0;
  }

  .view-all-adm-group-cards {
    padding: 5px 0px 10px;
  }

  .curve-up {
    min-height: 160px;
  }
  
  .like-cmnt-counting .share-post-card {
    margin-left: 13px;
  }

  .view-group-detail-members {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .view-group-detail-members .invite-remove-group-list-btn {
    margin-top: 10px;
  }
  .view-group-detail-members
    .invite-remove-group-list-btn
    > a#post:nth-child(1) {
    margin-left: 0px;
  }
  .view-group-detail-members
    .invite-remove-group-list-btn
    > a#post:nth-child(2) {
    background-color: #ff2424;
  }

  /* mobile-bottom-menu */

  .mobile-bottom-menu {
    position: fixed;
    display: flex;
    padding: 30px 40px;
    justify-content: space-between;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    /* margin: auto; */
    left: 0;
    bottom: 0;
    right: 0;
    height: 32px;
    font-size: 28px;
    /* background-image: radial-gradient(circle at 36px 6px,transparent 36px, #ffffff 37px); */
    background-image: url(../Images/mobile-bottom-menu.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(0px -1px 6px rgba(0, 0, 0, 0.08))
      drop-shadow(0px -2px 12px rgba(0, 0, 0, 0.12));
    /* border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px; */
    transition: 0.3s ease-in-out;
    z-index: 9;
    /* background-color: red; */
  }

  .mobile-menu-create-group {
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    /* position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -20px; */
    background-color: #27447a;
    font-size: 30px !important;
  }
  .mobile-bottom-menu i {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
  }
  .view-group-detail-members a.tmplt-btn {
    margin-top: 10px;
    margin-left: 0px;
  }

  .modal-body-create-grp {
    padding: 0 10px;

    height: calc(100vh - 230px);
    overflow-y: scroll;
  }
  .account-settings .privacy-card-outer {
    width: auto;
  }
  .button-UI.custom-button-UI .btn {
    margin-bottom: 8px;
  }
  .custom-signup-form .text-end .next.btn.next-button, .custom-signup-form .text-end .btn.submit.next-button, .custom-signup-form .text-end .previous.btn.previous-button-border, .signup-payment-page .plan-payment-form .btn-primary-theme.btn-outline {
    font-size: 12px !important;
    height: 36px;
    padding: 0.5rem 0.625rem;
  }
  .custom-signup-form .text-end .previous.btn.previous-button-border {
    margin-right: 8px;
  }
  .ui-design-gallery.media-page .has-icon {
    width: 100% !important;
  }
  .ql-container {
    font-size: 14px !important;
    min-height: 120px !important;
  }
  .advanced-template .banner-section {
    min-height: 250px;
    padding: 30px 0 !important;
  }
  .custom-template .banner-content p {
    font-size: 14px!important;
    line-height: 24px!important;
  }
  .edit-template-header ~ .custom-template.new-advanced-template {
    margin-top: 60px;
  }
}
@media (max-height:600px){
  .modal-body-create-grp {
      max-height: 400px;
  }
}
/*29/8/2022 css start here */
@media (max-width: 767px) {
  .admin-tools-menu .adm-tools-outer-box .adm-tools-card {
    width: 30%;
  }
  .bg_header_new_homepge {
    height: 100%;
    overflow: visible;
  }
  .abt-text {
    position: relative;
    z-index: 9;
  }
  .point h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  .clr-lowwhite {
    color: #838383;
    white-space: nowrap;
  }
  a.forget-link {
    text-decoration: none;
    color: #838383;
    white-space: nowrap;
  }
  main.get-started-bg .inner-container-form.mt-5 {
    margin: 0 !important;
  }
  .bg-grey-wall {
    padding: 0;
    margin: 0;
  }
  .media-posts-create-cmnt {
    align-items: start;
    padding: 12px 0px 10px;
  }

  .media-posts-create-cmnt br {
    display: none;
  }

  .commenttextbox {
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 100%;
  }
  .commenttextbox .react-emoji button.react-input-emoji--button {
    position: absolute;
    right: 3px !important;
    top: 8px;
  }
  .react-input-emoji--button svg {
    height: 18px;
    width: 18px;
  }
  .modal-emoji .btn-primary svg {
    height: 18px;
    width: 18px;
  }
  .commenttextbox .react-emoji .react-input-emoji--container {
    margin: 0 0px 15px 10px;
    max-height: 40px;
    font-size: 13px !important;
  }
  .media-posts-card {
    padding: 0;
  }
  .create-post-inner-box .create-post-text textarea {
    min-height: 120px;
    border-radius: 10px;
    font-size: 16px;
  }
  .like-cmnt-counting .counter {
    margin: 3px !important;
    font-size: 0;
    padding: 8px 15px;
  }
  .like-cmnt-countingcustom .counter span {
    margin-left: 0;
    font-weight: 100;
    font-size: 14px;
  }
  .group-tab-nav.custom-nav-tabs-main nav div#nav-tab button {
    font-size: 15px;
    padding: 10px 10px;
  }
  .post2,
  .post {
    font-size: 16px !important;
  }
  .media-posts-inner-box {
    margin: 15px 0;
  }
  .create-post-outer-box .create-post-inner-box .create-post {
    flex-direction: column;
    gap: 0;
  }

  .create-post-outer-box .create-post-inner-box .create-post label span {
    font-size: 14px;
  }

  .create-post-outer-box
    .create-post-inner-box
    .create-post
    .create-post-icons-upld {
    display: flex;
    flex-wrap: wrap;
  }

  .create-post-outer-box
    .create-post-inner-box
    .create-post
    .create-post-icons-upld
    label {
    padding: 15px 20px;
    margin-right: 10px;
    background: #f6f6f6;
    width: 100%;
    margin: 0;
    border-radius: 15px;
    text-align: center;
  }
  .inner-content-height {
    min-height: calc(100vh - 200px);
  }
  .admin-group-page .adm-group-heading {
    flex-direction: column;
    gap: 10px;
  }
  .manage-width {
    width: 100% !important;
  }

  .manage-width img {
    width: 100% !important;
    height: auto !important;
  }

  .button-UI {
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
  }

  .table-custom table thead tr th,
  .table-custom table tbody tr td {
    white-space: nowrap;
  }
  .table-custom table tbody tr td button {
    width: 100% !important;
    display: block;
    margin: 5px 0;
  }

  .admin-group-page .adm-group-heading h3 a {
    padding-bottom: 0;
  }
  .admin-group-page .adm-group-heading h3 {
    align-items: center;
  }
  .heading-main p {
    font-size: 18px;
    margin: 0;
  }
  .site-template-card-publish {
    display: block;
    text-align: center;
    margin-top: auto;
  }
  .basic-template .logo-text img {
    height: 34px;
  }
  .custom-template .banner-content {
    width: 100% !important;
  }
  .custom-template .banner-content h1 {
    font-size: 30px !important;
    line-height: 40px;
    word-break: break-word;
  }
  .custom-template .banner-content p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
  .custom-template .section-content h2 {
    font-size: 30px !important;
    line-height: 40px !important;
    margin-bottom: 10px!important;
  }
  .custom-template .section-content p {
    font-size: 16px !important;
  }
  .custom-template .btn-primary {
    font-size: 15px !important;
    padding: 0.65rem 1.125rem !important;
  }
  .custom-template .edit-btn i {
    font-size: 14px;
  }
  .youtube-video iframe,
  .youtube-video video {
    height: 300px !important;
  }
  .custom-box .card-body {
    padding: 24px;
  }
  .group-image img {
    height: 180px !important;
  }
  .custom-box .user-img {
    height: 40px;
    width: 40px;
  }
  .group-section-template .section-title {
    margin-bottom: 25px !important;
  }
  .custom-template h2 {
    font-size: 32px !important;
    margin-bottom: 10px !important;
  }
  .custom-template .section-content h3 {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .contact-form {
    padding: 20px;
    margin-top: 25px !important;
  }
  .contact-form .form-group {
    margin-bottom: 20px !important;
  }
  .custom-box .card-subtitle {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }
  .custom-box.small-box .card-body .card-subtitle {
    margin-bottom: 0 !important;
  }
  .custom-ui-header {
    padding: 14px 16px;
  }
  .login-container-inner,
  .forgot-password-inner {
    padding: 10px;
  }
  .login-cstm-button {
    margin: 0;
  }
  .Login-form::after {
    display: none;
  }
  .login-container {
    min-height: auto;
  }
  .login-main-heading p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .abt-text h3 {
    font-size: 30px;
    line-height: normal;
  }
  .section-title__title {
    font-size: 30px;
  }
  .form-box {
    padding: 20px;
  }
  .submit-btn button.btn {
    margin-top: 15px;
    padding: 10px 35px;
    width: auto;
  }
  .form-box .form-group {
    margin: 10px 0 10px;
  }
  .login-cstm-button .login-btn {
    font-size: 18px
  }
  #top-nav-header.header-new button.navbar-toggler i {
    color: #fff;
  }
  .bg_header_new_homepge-new .navbar-toggler {
    float: right;
    margin: 7px 8px 0 0;
    font-size: 18px;
  }
  .bg_header_new_homepge-new .get-started-links {
    margin-top: 10px;
  }
  .bg_header_new_homepge-new #top-nav-header ul.get-started-links li {
    margin: 0;
  }
  .bg_header_new_homepge-new .main-outer-container {
    padding-top: 0;
  }
  .community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label {
    font-size: 13px;
  }
  .community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label .price-text {
    font-size: 18px;
  }
  .plan-payment-amount p {
    text-align: right;
  }
  .signup-payment-page .h-150 {
    width: auto;
    height: 120px !important;
  }
  .left-sidebar {
    top: 50px;
  }
  .left-sidebar .sidebar-inner ul {
    margin: 0;
    padding: 0px 8px 0px;
    max-height: calc(100vh - 296px);
  }
  .sidebar-user-img img {
    height: 90px !important;
    width: 90px !important;
  }
  .left-sidebar .sidebar-list-outer .icon {
    margin-right: 15px;
  }
  .left-sidebar .sidebar-list-outer li {
    margin-bottom: 5px;
  }
  .left-sidebar .sidebar-list-outer li:last-child {
    margin-bottom: 0;
  }
  input, select {
      height: 42px;
      font-size: 0.875rem !important;
  }
  .h5, h5, .h4, h4 {
    font-size: 1rem;
  }
  .custom-card {
    min-height: calc(100vh - 135px);
  }
  .horizontal-tabs .nav-container {
    padding: 20px 20px 10px;
  }
  .horizontal-tabs .tab-content {
    padding: 0 20px;
  }
  .about-fields {
    margin-bottom: 20px;
  }
  .commenttextbox .btn {
    margin-left: 0;
    margin-top: 12px;
    font-size: 11px;
    padding: 0.35rem 0.75rem;
  }
  .discussions-container .add-like-comment {
    padding: 14px 0 14px;
  }
  .discussions-container .add-like-comment .like {
    margin-right: 20px;
  }
  .discussions-container .image-container {
    margin: 0 15px 0 15px;
  }
  .discussions-container .media-posts-card-content .discussions-list-image {
    height: 80px;
    width: 80px;
    min-width: 80px;
    min-height: 80px;
  }
  .discussions-container .media-posts-card-content h5 {
    font-size: 15px;
    line-height: normal;
  }
  .discussions-container:not(.modal) .media-posts-card-content p {
    max-height: 48px;
  }
  .discussions-container .media-posts-card-content, .discussions-container .media-posts-card-content p {
    font-size: 13px;
    line-height: 23px;
  }
  .modal.discussions-container .add-like-comment .custom-btn {
    font-size: 14px;
  }
  /* .create-post .btn-primary-theme {
    width: 100%;
  } */
  .event-box .event-info-header {
    min-height: auto !important;
  }
  .inner-tabs .nav-tabs .nav-link {
    margin: 0 12px !important;
    font-size: 14px;
  }
  .inner-tabs .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
  }
  .event-box .top-img {
    height: 150px;
  }
  .video-play {
    height: 120px;
  }
  .view-group-detail-about.cusom-group-design.bg-white {
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
  }
  .Profile-picture-group .profile-image {
    height: 120px;
    width: 120px;
  }
  .view-group-detail-about.cusom-group-design.bg-white {
    padding: 12px;
  }
  .view-group-detail-about .Profile-picture-group .profile-image {
    height: 110px;
    width: 110px;
    margin-top: -35px;
    margin-right: 15px;
  }
  .view-group-detail-about.cusom-group-design.bg-white h3 {
    font-size: 15px;
  }
  .view-group-detail-about.cusom-group-design p {
    font-size: 13px;
  }
  .view-group-detail-members .badge,
  .view-group-detail-members .btn-primary-theme {
    font-size: 14px;
  }
  .Profile-picture-group span {
    font-size: 14px;
  }
  .create-event-btn, .create-discussion-btn {
      position: relative;
      top: 0;
      right: 0;
      margin-bottom: 15px;
  }
  .group-tab-nav.custom-nav-tabs-main .nav-link {
    white-space: nowrap;
  }
  .account-settings .privacy-card-outer {
    padding: 14px;
  }
  .account-settings .privacy-card-outer h5 {
    font-size: 1rem;
  }
  .calendar-accordion .grid-table-view-icon {
    position: relative;
    top: -8px;
  }
  .custom-accordion.group-accordion.calendar-accordion .accordion-header {
    width: 100%;
  }
  .event-details-header.custom-profile-picture-group .profile-image {
    margin-top: -30px;
    height: 110px;
    width: 110px;
  }
  .modal-body .group-cover-image img {
    height: 160px;
  }
  .edit-template-header ~ .custom-template {
    padding-top: 60px;
  }
  body {
    font-size: 15px;
  }
  .privacy-card-outer {
    padding: 12px 16px;
    margin-bottom: 20px;
  }
  .account-settings .table > :not(caption) > * > * {
    padding: 0.5rem 0.875rem;
  }
  .upgrade-plan-page .community-plan-accordion .card .card-header .custom-control {
    flex-direction: row;
    align-items: center;
  }
  .upgrade-plan-page .community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label span {
    font-size: 14px;
  }
  .upgrade-plan-page .community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label sup {
    font-size: 14px;
    top: 5px;
  }
  .upgrade-plan-page #msform fieldset {
    padding: 0;
  }
  .community-plan-accordion .card .card-header .custom-control .card-header-prg p {
    font-size: 14px;
    min-width: 110px;
    white-space: normal;
    text-align: right;
  }
  .Btn-fill {
    font-size: 14px !important;
    padding: 9px 16px!important;
  }
  .common-card .card-body .card-body {
    padding: 1rem 0;
  }
  .w-50p {
    width: 100% !important;
  }
  .upgrade-plan-page .get-started-bg-new  {
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .award-image img {
    height: 80px !important;
  }
  .congratulation-box {
    margin-top: 0 !important;
  }
  .group-page .small-profile-image svg {
    height: 24px;
  }
  .invite-members-social-media.custom input {
    width: 100% !important;
  }
  .create-group-error {
    margin-left: 150px;
  }
  .tools-row.row, .small-row.row {
    margin-right: -0.438rem;
    margin-left: -0.438rem;
  }
  .tools-row.row > *, .small-row.row > * {
    padding-right: 0.438rem;
    padding-left: 0.438rem;
  }
  .tools-row.row .col {
    margin-bottom: 14px;
  }
  .group-page.user-edit-profile-page .image-close-icon,
  .group-page.user-edit-profile-page .image-edit-icon {
    top: 0;
    right: 0;
  }
  .group-page .form-label, .offcanvas .form-label {
    font-size: 13px !important;
  }
  .group-page .form-control, .group-page .form-select {
    padding: 0.5rem 0.75rem !important;
  }
  .group-page .form-group {
    margin-bottom: 15px;
  }
  .group-tab-nav.custom-nav-tabs-main .nav-link {
    margin: 0 15px;
    font-size: 14px;
  }
  .community-card .custom-card .card-body:first-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .privacy-card-outer .privacy-icon svg {
    height: 20px;
  }
  .privacy-icon {
    margin: 4px 14px 0 0;
  }
  .group-accordion .site-template-card-outer .site-template-card-image img:not(.copy-link-image, .edit-icon-image) {
    height: 150px;
  }
  .btn.downloadFileButton {
    right: 16px;
    margin-top: -50px;
  }
  .media-posts-card-image img {
    max-height: 150px;
  }
  .custom-media-event .nav-link {
    margin: 0 12px !important;
  }
  .media-group-box .edit-site-tmplt.modal .small-btn,
  .event-box-container .event-box .edit-site-tmplt .small-btn {
    height: 28px !important;
    width: 28px !important;
  }
  .media-group-box .edit-site-tmplt .btn svg,
  .event-box-container .event-box .edit-site-tmplt .btn svg {
    height: 14px !important;
    width: 14px !important;
  }
  .cstm-search .members-group-lists-search {
    min-height: 42px;
  }
  .cstm-search .copy-url-btn {
    font-size: 14px;
    width: 75px;
  }
  .font-size-18 {
    font-size: 16px;
  }
  .font-size-17 {
    font-size: 16px !important;
  }
  .invite-members-social-media h3 {
    font-size: 16px;
  }
  .member-image {
    height: 30px !important;
    width: 30px !important;
  }
  .custom-card-design {
    padding: 15px 0 0 15px;
  }
  .custom-card-design .flex--about span {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    line-height: 28px;
    margin-right: 13px;
  }
  .custom-card-design .flex--about span svg {
    height: 14px;
    width: 14px;
  }
  .custom-card-design .flex--about .areatext h3 {
    font-size: 15px;
  }
  .custom-card-design .flex--about .areatext p {
    font-size: 13px;
  }
  .group-cover-img img {
    height: 150px;
  }  
  .create-post-text .react-input-emoji--container {
    min-height: auto;
  }
  .create-post-text .react-input-emoji--placeholder {
    font-size: 13px;
    left: 15px;
  }
  .react-input-emoji--button {
    right: 2px !important;
  }
  .create-post-text .react-input-emoji--input {
    padding: 5px 12px 6px 15px !important;
    line-height: 25px;
  }
  .media-posts-card .media-posts-card-header .media-posts-card-profile-name p {
    font-size: 14px;
  }
  .media-posts-card .media-posts-card-header .media-posts-card-profile-name span {
    font-size: 10px;
    position: relative;
    top: -2px;
  }
  .create-post-outer-box .create-post-inner-box .create-post label span {
    display: block;
  }
  .create-post-outer-box .create-post-inner-box .create-post .create-post-icons-upld label {
    padding: 8px 10px;
    border-radius: 4px;
  }
  .create-post-outer-box .create-post-inner-box .create-post .create-post-icons-upld label svg {
    margin-right: 0 !important;
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
  }
  .create-post-outer-box .create-post-inner-box .create-post {
    padding-top: 10px;
    margin-top: 12px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .thumbnail-preview {
    margin: 20px 0 5px;
  }
  .like-cmnt-counting .cmnt-count, .like-cmnt-counting .likes-count {
    font-size: 13px !important;
  }
  .add-like-comment {
    font-size: 13px;
  }
  .media-posts-card-header .media-posts-card-profile .media-posts-card-profile-pic img {
    width: 35px;
    height: 35px;
    min-width: 35px;
  }
  .create-post-inner-box {
    padding: 15px 15px 10px;
  }
  .media-posts-card .media-posts-card-content {
    padding: 5px 16px 12px;
    word-break: break-word;
    line-height: 25px;
    font-size: 13px;
  }
  .modal-emoji textarea {
    font-size: 13px !important;
  }
  .view-group-detail-members .badge, .view-group-detail-members .btn-primary-theme {
    font-size: 12px;
  }
  .view-group-detail-members .badge svg, .view-group-detail-members .btn-primary-theme svg {
    height: 14px;
    width: 14px;
  }
  .thumbnail-preview .close-icon svg {
    height: 30px;
    width: 30px;
  }
  .media-posts-card-pinned {
    padding: 10px 14px 10px;
  }
  .pinned-text {
    font-size: 0.938rem;
  }
  .profile-media h5 {
    font-size: 16px;
  }
  .font-size-14 {
    font-size: 12px !important;
  }
  .profile-user-image {
    height: 100px;
    width: 100px;
  }
  .checkbox-container h6 {
    font-size: 0.938rem;
  }
  .checkbox-container img {
    height: 40px;
    width: 40px;
  }
  .comment-details-container {
    min-width: 100%;
  }
  .commentitem-inner {
    max-width: 80%;
  }
  .modal .comments-section-custom .create-post-icons-upld .row {
    width: auto !important;
  }
  .modal .post-commentsection .create-post-outer-box .create-post-inner-box .create-post {
    flex-direction: column !important;
  }
  .modal.discussions-container .comments-section-custom .thumbnail-image-post-img, .modal.discussions-container .comments-section-custom .thumbnail-video-post-img, .modal.discussions-container .comments-section-custom .docfile {
    margin: 0px 0 10px;
  }
  .modal.discussions-container .thumbnail-image-post-img, .modal.discussions-container .thumbnail-video-post-img {
    margin: 0px 0 12px;
  }
  .custom-accordion.group-accordion .accordion-button {
    font-size: 0.938rem;
  }
  .why-choose-image .main-image {
    height: 150px!important;
  }
  .table-profile-image {
    min-height: 35px;
    min-width: 35px;
    height: 35px;
    width: 35px;
  }
  .new-media-box .group-img img {
    height: 100px;
  }
  .new-media-box .media-group-box .edit-site-tmplt.modal .small-btn svg {
    height: 12px;
    width: 12px !important;
    margin-top: -3px;
  }
  .new-media-box .edit-site-tmplt {
    top: 7px;
    right: 7px;
  }
  .media-group-box .edit-site-tmplt.modal {
    padding-right: 6px;
  }
  .new-media-box .media-group-box .edit-site-tmplt.modal .small-btn {
    height: 25px;
    width: 25px;
  }
  .new-media-box .video-play {
    height: 100px;
  }
  .new-media-box .docfile {
    padding: 10px;
    height: 100px;
  }
  .new-media-box .docfile .doc-icon {
    font-size: 24px;
  }
  .ui-design-gallery.media-page .has-icon {
    margin-top: -10px !important;
  }
  .back-icon svg {
    height: 12px;
    margin-right: 8px;
  }
  .message-card .card-header h6 {
    font-size: 0.875rem;
  }
  .personal-message-card .card-footer {
    padding: 0.375rem 0.875rem;
  }
  .form-box .form-group textarea {
    font-size: 0.875rem !important;
  }
  .form-addess h3 {
    font-size: 18px;
    margin-bottom: 14px;
  }
  .form-addess p, .form-addess a {
    font-size: 15px;
  }
  .form-addess p i, .form-addess a i {
    font-size: 15px;
  }
  .view-group-detail-about .group-content img {
    height: 26px;
    width: 26px;
    min-width: 26px;
    min-height: 26px;
  }
  .advanced-template .logo-text {
    font-size: 1rem;
    max-width: 81%;
    margin-right: 0.5rem;
  }
  .advanced-template .logo-text span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .advanced-template .navbar .navbar-toggler {
    font-size: 1.125rem;
    padding: 0.25rem 0.5rem;
  }
  .advanced-template .navbar img {
    height: 35px;
  }
  .media-posts-card-header .media-posts-card-profile-name {
    white-space: nowrap;
    margin-right: 12px;
  }
  .modal.common-card .media-posts-card-header .media-posts-card-profile-name {
    white-space: normal;
  }
  .modal.common-card .media-posts-card-profile-name p {
    margin: 0 !important;
  }
  .modal.common-card .media-posts-card-profile-name span {
    word-break: break-word;
    line-height: 17px;
    display: inline-block;
  }
  .media-posts-card-header .group-name svg {
    min-height: 16px;
    min-width: 16px;
  }
  .modal-emoji .btn-primary {
    top: 14px !important;
    right: 2px;
  }
  .common-modal.edit-comment-modal.discussions-container .modal-emoji .btn-primary {
    top: 9px !important;
  }
  .comments-section-custom.inner-child .commentitem-inner + form {
    padding-bottom: 10px;
  }
  .media-posts-create-cmnt1.inner-content img.commentprofile,
  .media-posts-create-cmnt img.commentprofile2 {
    height: 35px;
    width: 35px;
  }
  #top-admin-header a.navbar-brand img {
    height: 18px;
    margin-top: -6px;
  }
  .modal-emoji textarea {
    padding: 12px 40px 3px 15px !important;
    width: 100%;
    min-height: auto;
    line-height: 12px !important;
  }
  .congratulations-form {
    padding: 0 !important;
    margin-top: 30px;
  }
  .icon-counter {
    width: 60px;
    height: 60px;
  }
  .icon-counter svg {
    width: 28px;
    height: 28px;
  }
  .icon-counter-single {
    margin-bottom: 20px;
  }
  .icon-counter-content .counter {
    font-size: 16px;
    margin-top: 8px;
  }
  .icon-counter-content p {
    font-size: 15px;
    margin-top: 10px;
  }
  .point p {
    font-size: 15px;
  }
  .login-container-inner .field-set-form button {
    top: 38px;
    right: 10px;
    font-size: 15px;
  }
  .left-sidebar .sidebar-inner ul.change-plan-btn {
    max-height: calc(100vh - 295px);
  }
  .community-plan-accordion .card {
    margin-bottom: 15px;
  }
  .upgrade-plan-page .custom-signup-form {
    padding: 15px;
  }
  .plan-payment-body .plan-payment-amount p, .upgrade-plan-page .main-heading, .plan-payment-body .plan-payment-amount h4 {
    font-size: 15px;
  }
  .my-profile-page .create-post-outer-box .create-post-inner-box .create-post {
    padding: 10px 0 8px !important;
  }
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #e0e0e0;
    border-top: 4px solid #393e51;
  }
  .file-reader-option {
    height: 250px;
  }
  .group-name.single {
    margin-top: 8px;
  }
  .discussions-container .media-posts-card-header .media-posts-card-profile .media-posts-card-profile-pic img {
    width: 35px;
    height: 35px;
  }
  .discussions-container .media-posts-card .media-posts-card-header .media-posts-card-profile-name p {
    font-size: 14px;
  }
  .discussions-container .media-posts-card .media-posts-card-header .media-posts-card-profile-name span {
    font-size: 11px;
  }
  .roles-table .table {
    font-size: 14px;
  }
  .w-25 {
    width: 50% !important;
  }
  .calendar-pills .nav-pills {
    margin-bottom: 10px;
  }
  .calendar-pills .nav-pills .nav-link {
    padding: 0.55rem 1rem;
    font-size: 13px;
  }
  .grid-table-view-icon .btn {
    height: 32px;
    width: 32px;
  }
  .grid-table-view-icon .btn svg {
    height: 14px;
    width: 14px;
  }
  .upgrade-plan #msform {
    margin-top: 25px !important;
  }
  .community-members .dropdown {
    margin-left: 30px !important;
  }
  .custom-template h2 {
    line-height: 40px !important;
  }
  .custom-template section {
    padding: 40px 0 !important;
  }
  .support-page {
    padding-top: 0 !important;
  }
  .support-page .card-body {
    padding-bottom: 0 !important;
  }
  .create-post-inner-box .create-post-text img {
    width: 35px;
    height: 35px;
  }
  .media-posts-outer-box .thumbnail-preview {
    margin: 5px 0 15px;
  }
  .post-commentsection .modal-emoji .btn-primary {
    top: 14px !important;
  }
  .comment-details p {
    font-size: 13px;
  }
  .view-more-comment {
    font-size: 13px;
  }
  .discussion-dropdown .dropdown-toggle svg {
    height: 17px;
  }
  .modal.discussions-container .media-group-box {
    margin-top: 0 !important;
  }
  .common-modal.discussions-container img.commentprofile {
    width: 35px;
    height: 35px;
  }
  .common-modal.discussions-container .modal-emoji .btn-primary {
    top: 15px !important;
  }
  .modal.discussions-container .like-cmnt-counting .like svg {
    height: 16px;
    width: 16px;
  }
  .chat-posts-card-profile .media-posts-card-profile-pic img {
    height: 35px;
    width: 35px;
    min-width: 35px;
  }
  .send-btn {
    padding: 0.3125rem 0.5rem;
  }
  .send-btn svg {
    height: 24px;
    width: 24px;
  }
  .chat-message .react-input-emoji--container {
    font-size: 13px !important;
  }
  .create-event-form .form-label {
    font-size: 13px !important;
  }
  .create-group-event .modal-create-group-upld-img .create-group-upld-img label img {
    height: 150px;
  }
  .modal.discussions-container .edit-site-tmplt {
    top: 8px;
  }
  .event-box .event-info-header h5 {
    font-size: 16px;
  }
  .modal.discussions-container .media-group-box.docfile, .modal.discussions-container .video-play, .modal .media-group-box .group-img img {
    height: 90px;
  }
  .font-size-15 {
    font-size: 14px;
  }
  .pagination {
    flex-wrap: wrap;
  }
  .pagination .page-link {
    font-size: 13px;
  }
  .custom-btn-group label {
    font-size: 13px !important;
  }
  .group-section-template .group-box .group-content {
    margin-left: 8px !important;
  }
  .notifications-list {
    font-size: 0.813rem;
  }
  .create-community-page .main-heading {
    font-size: 18px;
  }
  .create-community-page .main-sub-heading {
    font-size: 16px;
  }
  .mb-20 {
    margin-bottom: 16px;
  }
  .chat-member-list {
    max-height: 250px;
  }
  #top-nav-header.custom-ui-header ul.get-started-links li {
    margin-left: 3px;
  }
  #top-nav-header ul.get-started-links li a {
    font-size: 15px;
  }
  .point::after { 
    left: -75px;
    top: 7px;
    height: 25px;
    width: 25px;
  }
  #top-nav-header.header-new a.navbar-brand img {
    width: 100px;
  }
  .account-settings .tab-content .custom-card {
    min-height: calc(100vh - 425px);
  }
  .custom-template.advanced-template .banner-content h1 {
    font-size: 26px!important;
    line-height: 32px!important;
  }
  .custom-template.advanced-template .banner-content .btn-primary {
    margin-top: 15px;
  }
  .advanced-template .template-tabs-section .tab-content img,
  .advanced-template .fitness-gallery-section .images img,
  div.new-advanced-template .fitness-gallery-section .images img {
    height: 180px !important;
    border-radius: 10px;
  }
  /* div.new-advanced-template .fitness-gallery-section .images img {
    border-radius: 0 !important;
  } */
  .small-gap {
    --bs-gutter-x: 1rem;
  }
  .modal-gallery-list .media-group-box img{
    height: 140px !important;
  }
  .modal .small-profile-image .camera-icon {
    height: 26px;
  }
  .group-page .form-control, .group-page .form-select {
    font-size: 0.875rem;
  }
  .group-page .form-control {
    height: 40px;
  }
  .event-details-body .btn {
    font-size: 13px;
  }
  .event-details-header h4 {
    font-size: 16px;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .full-calendar .fc .fc-toolbar-title {
    font-size: 1.125em !important;
  }
  .full-calendar .fc .fc-button {
    font-size: 13px;
  }
  .full-calendar .fc table {
    font-size: 0.688rem;
  }
  .fc .fc-view-harness {
    height: 275px !important;
  }
  .calendar-accordion .full-calendar {
    padding: 15px 5px;
  }
  .fc .fc-daygrid-day-events {
    margin-top: -7px;
  }
  .media-file-card {
    height: 120px;
  }
  .new-media-box.folder-media-box .group-img img {
    height: 120px !important;
  }
  .new-media-box.folder-media-box .dropdown-toggle, .media-file-card-container .btn-link.dropdown-toggle {
    height: 28px;
    width: 28px;
  }
  .folder-media-box .media-group-box .edit-site-tmplt .btn svg, .media-file-card-container .edit-site-tmplt .btn svg {
    height: 18px !important;
    width: 18px !important;
  }
  .media-file-card .MuiSvgIcon-root,
  .files-modal .media-file-card .MuiSvgIcon-root {
    font-size: 32px !important;
  }
  .modal .comments-section-custom .commentitem-inner,
  .discussions-container .comments-section-custom.inner-child .media-posts-create-cmnt.inner-content > form,
  .discussions-container .comments-section-custom.inner-child .media-posts-create-cmnt1.inner-content > form {
    max-width: 100%;
  }
  .custom-template .banner-content p {
    margin-bottom: 0 !important;
  }
  .custom-template.advanced-template .banner-content p { 
    margin-bottom: 0 !important;
  }
  div.custom-template.new-advanced-template .banner-content h1 {
    line-height: 40px !important;
    margin-bottom: 10px !important;
  }
  div.custom-template.new-advanced-template .banner-content p {
    line-height: 28px !important;
  }
  .custom-template.new-advanced-template .section-content h3 {
    padding-bottom: 15px !important;
    margin-bottom: 10px !important;
  }
  .custom-template.new-advanced-template p {
    line-height: 26px !important;
  }
  .custom-template.new-advanced-template .section-content h2 {
    font-size: 30px !important;
  }
  div.new-advanced-template section .btn.ms-3 {
    padding: 0.61rem 1.125rem !important;
    margin-left: 0.75rem !important;
  }
  .new-advanced-template .common-section img {
    max-height: 250px;
  }
  .shared-media {
    padding-right: 15px !important;
  }
  .common-modal.discussions-container .modal-body {
    padding: 1rem;
  }
  .create-post .btn-primary-theme {
    font-size: 11px;
    white-space: nowrap;
    padding: 0.3125rem 0.75rem;
  }
  .table-actions .copy-icon.table-view {
    margin-right: 6px !important;
  }
  .search-reset-box {
    margin: 0;
  }
  .live-stream-video,
  .live-stream-video iframe {
    height: 300px;
  }
  .privacy-policy-cntnt-box .prv-pol-cntnt .prv-pol-cntnt-heading {
    font-size: 17px;
  }
  .privacy-policy-cntnt-box p,
  .privacy-policy-cntnt-box ul li {
    font-size: 14px;
  }
  .simple-modal .live-stream-video,
  .simple-modal .live-stream-video iframe,
  .simple-modal .live-stream-video div,
  .simple-modal .live-stream-video video {
    height: 250px !important;
  }
  .simple-modal div.live-video-image-count,
  .simple-modal div.live-video-image-count div {
    height: auto !important;
  }
  .simple-modal .live-stream-video .live-video-image-count .dropdown-toggle {
    font-size: 13px;
  }
  .modal .countdown-timer {
    padding: 7px 12px;
  }
  .countdown-timer {
    font-size: 13px;
  }
  .h-44 {
    height: 40px;
  }
  .community-img img {
    height: 35px !important;
    width: 35px !important;
  }
  .custom-dashboard-community .btn-link.dropdown-toggle.btn-primary {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .table-custom table tbody tr td button {
    width: 100% !important;
    display: block;
    margin: 5px 0;
  }
  .admin-tools-menu .adm-tools-outer-box .adm-tools-card {
    width: 30%;
  }
  .admin-group-page .adm-group-heading {
    flex-direction: column;
    gap: 10px;
  }
  .table-custom table thead tr th,
  .table-custom table tbody tr td {
    white-space: nowrap;
  }

  .admin-group-page .adm-group-heading h3 a {
    padding-bottom: 0;
  }
  .admin-group-page .adm-group-heading h3 {
    align-items: center;
  }
  .heading-main p {
    font-size: 18px;
    margin: 0;
  }

  .site-template-card-publish {
    display: block;
    text-align: center;
    margin-top: auto;
  }
  .h5, h5 {
      font-size: 1.125rem;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .table-custom table tbody tr td button {
    width: 100% !important;
    display: block;
    margin: 5px 0;
  }

  .table-custom table thead tr th,
  .table-custom table tbody tr td {
    white-space: nowrap;
  }

  .admin-group-page .adm-group-heading h3 a {
    padding-bottom: 0;
  }
  .admin-group-page .adm-group-heading h3 {
    align-items: center;
  }
  .heading-main p {
    font-size: 18px;
    margin: 0;
  }
  .site-template-card-publish {
    display: block;
    text-align: center;
    /* margin-top: auto; */
  }
}

@media (max-width: 480px) {
  #top-nav-header a.navbar-brand img {
    width: 80px;
  }
  .group-detail-card-name {
    position: absolute;
    top: 15px;
    margin: 0 10px;
    right: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    gap: 15px;
  }
  .bg-grey-wall {
    padding: 0;
    margin: 0;
  }
  .view-group-detail-about.cusom-group-design.bg-white h3.grpname {
    width: auto;
  }

  .view-group-detail-about.cusom-group-design.bg-white
    .view-group-detail-members
    button.post2 {
    margin-left: 0;
  }
  .admin-tools-menu .adm-tools-outer-box .adm-tools-card {
    width: 45%;
    max-width: 100%;
    margin: 5px;
  }
  /* button  */

  .tmplt-btn {
    padding: 8px 20px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    max-width: 100%;
  }

  .btn-get-started {
    padding: 8px 20px;
    margin-bottom: 20px;
    font-size: 15px;
  }
  .buttons_flex .btn {
    margin-right: 0 !important;
  }

  .next-button {
    padding: 8px 20px;
    font-size: 16px;
    min-width: 132px;
  }

  .previous-button-border {
    padding: 8px 20px;
    font-size: 16px;
    min-width: 132px;
    margin-right: 10px;
  }
  .let-get-rock-btn {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    min-width: 180px;
  }

  .template-header-edit-btn a.edit-template-btn {
    font-size: 16px;
    padding: 8px 20px;
  }
  .skip-template-btn {
    font-size: 16px;
    padding: 8px 20px;
  }

  .main-inner-container .get-started-prg {
    font-size: 15px;
  }

  /* #progressbar li:before {
    width: 45px;
    height: 45px;
  } */

  /* #progressbar li:after {
    top: 20px;
  } */

  .main-heading {
    font-size: 18px;
  }

  #msform fieldset {
    border: 0 none;
    padding: 20px 0 0;
    box-sizing: border-box;
  }

  #msform {
    margin-top: 20px;
  }

  .setting-progress-box h3 {
    font-size: 16px;
    margin: 20px 10px 0;
  }

  .setting-progress-box > img {
    width: 80px;
    margin: 0px 0 5px;
  }

  .plan-payment {
    /* padding: 20px 30px 30px; */
    margin-top: 0px;
  }

  .curve-up {
    min-height: 126px;
  }
  
  .congratulation-box {
    margin-top: 80px;
  }

  .congratulation-box {
    margin-top: 100px;
  }

  .congratulation-box h3 {
    font-size: 20px !important;
  }

  .congratulation-box .cong-heading {
    font-size: 24px !important;
  }

  .congratulation-box p {
    margin-bottom: 25px;
    font-size: 14px;
  }

  .community-plan-accordion .card .card-header .custom-control {
    flex-direction: column;
    align-items: flex-start;
  }

  .plan-payment {
    padding: 20px 0px 30px;
  }

  .plan-payment-images img {
    width: 40px !important;
    margin: 0 8px;
  }

  .plan-payment-body
    .plan-payment-form
    .field-set-form
    .form-group
    .field-expire-date {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .adm-header-nav li.adm-header-search i {
    font-size: 18px;
  }

  .adm-header-nav li.adm-notification i {
    font-size: 18px;
  }

  .admin-profile-pic img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .adm-profile-drop a .admin-profile-pic-name {
    font-size: 16px;
  }
  .adm-header-nav li {
    margin: 0 5px;
  }

  .header-toggle-cstm i {
    font-size: 20px;
  }
  .header-toggle-cstm {
    margin-left: 5px;
  }

  .view-group-detail-members ul li img {
    width: 40px;
    height: 40px;
    border: 2px solid #40c1c5;
    border-radius: 100px;
    object-fit: cover;
  }

  .setting-prgrs-website-container div div > h2 {
    font-size: 32px;
  }

  .all-sites-template-container {
    height: 100%;
  }
  .create-group-error {
    font-size: 11px;
  }
  .view-group-detail-members {
    margin-left: inherit !important;
  }
  .w-25 {
    width: 100% !important;
  }
  .react-confirm-alert {
    margin: 15px;
  }
  .react-confirm-alert-body {
    width: 100% !important;
    padding: 20px;
  }
  .react-confirm-alert .react-confirm-alert-body h1 {
    font-size: 22px;
  }
  .media-search-container .has-icon {
    width: 195px !important;
  }
}

@media (max-width: 380px) {
  .custom-button-UI .form-select {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .w-275,
  .res-100 {
    width: 100% !important;
  }
  .h-33 {
    height: auto !important;
  }
  .media-posts-card .media-posts-card-header {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .media-posts-card .media-posts-card-header .group-name {
    justify-content: start !important;
    
  }
  .media-posts-card .media-posts-card-header .group-name ~ .discussion-dropdown {
    margin-left: 10px;
  }
  .media-posts-card .media-posts-card-header .group-container {
    margin: 10px 0 0;
  }
}

@media (max-width: 350px) {
  .adm-header-nav .nav-upgrade-plan-btn {
    padding: 0.266rem 0.4rem;
  }
  #top-admin-header .navbar-brand {
    font-size: 16px;
    margin: 0 8px;
  }
  .admin-profile-pic img {
    width: 25px;
    height: 25px;
  }
  .left-sidebar .sidebar-inner ul {
    max-height: calc(100vh - 345px);
  }
  .create-post-text .react-input-emoji--placeholder {
    font-size: 11px;
  }
  .react-input-emoji--container {
    padding-right: 40px !important;
  }
  .user-edit-profile-page .create-group-upld-img.Custom-profile-image {
    max-width: 110px;
    height: 110px;
  }
  .user-edit-profile-page.group-page .modal-create-group-upld-img .create-group-upld-img label, .user-edit-profile-page .modal-create-group-upld-img .create-group-upld-img label img {
    height: 110px !important;
  }
  .image-close-icon svg {
    height: 32px;
    width: 32px;
  }
  .user-edit-profile-page.group-page .modal-create-group-upld-img .create-group-upld-img label.image-edit-icon {
    height: 32px !important;
    width: 32px !important;
  }
  .common-tabs .nav-link {
    padding: 0.5rem 1rem 1rem;
  }
  .site-template-card-outer .box-btn.btn-secondary-theme {
    height: 32px;
    width: 32px;
    line-height: 15px;
  }
  .site-template-card-outer .box-btn.btn-secondary-theme svg,
  .site-template-card-outer .box-btn.btn-secondary-theme img {
    height: 15px;
  }
  .create-group-event .modal-create-group-upld-img .create-group-upld-img label img {
    height: 125px;
  }
}
@media (max-width: 310px) {
  .community-btn {
    margin-top: 8px;
  }
}

/*29/8/2022 css end here */