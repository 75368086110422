/* .bg_header_new_homepge_bg-color {
  background-image: url(../Images/home-banner-image.JPG);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-color: #3F444D;
  opacity: 0.8;
} */
.bg_header_new_homepge-new::before {
  content: "";
  background-color: transparent;
  background: rgb(0 0 0 / 56%);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #3F444D;
}


.bg_header_new_homepge-new {
  height: "100%";
  /* overflow: hidden; */
  background-image: url(../Images/home-banner-image.jpg);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}


/* progresbar */

#progressbar li.active:before,
#progressbar li.active:after {
  background: #2EBC00;
  color: white;
  border: 2px solid #2EBC00;
}

#progressbar li:before {
  content: "";
  width: 40px;
  height: 4px;
  display: block;
  background: #6b955e;
  border-radius: 50px;
  border: 2px solid #ffffffdb;
  /* box-shadow: 1px 5px 10px #ddc5c57a; */
}

#progressbar {
  margin: 0 auto 25px;
  overflow: hidden;
  text-align: center;
  padding: 0;
  width: 100%;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 40px;
  float: left;
  position: relative;
  letter-spacing: 1px;
  margin-right: 10px;
}

.main-heading {
  color: #3F444D;
  font-weight: 600;
  font-size: 28px;
  margin: 20px 0 0;
}

.main-sub-heading {
  color: #2EBC00;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
}

/* form */

.field-set-form .cstm-field {
  margin-bottom: 0;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #E1E6EF;
  color: #3F444D !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #94A0B8 !important;
  outline: 0;
  box-shadow: none;
}

.form-label {
  color: #94A0B8 !important;
  font-size: 12px !important;
}

.form-check-input:checked {
  background-color: #047ED6 !important;
  border-color: #047ED6 !important;
  box-shadow: none;
}

.form-check-label {
  color: #5F6C85 !important;
  font-size: 14px !important;
  cursor: pointer;
}

/* button */

.custom-signup-form .text-end .next.btn.next-button,
.custom-signup-form .text-end .btn.submit.next-button {
  background: #047ED6;
  border-color: #047ED6;
  font-size: 16px;
  border-radius: 6px !important;
  color: #fff;
  text-transform: uppercase;
  max-width: 130px;
  width: 100%;
  font-weight: 600;
  padding: .5rem .75rem;
  height: auto;
}

/* navbar */
nav#top-nav-header ul.get-started-links li a.Login-button.login {
  padding: 5px 10px 4px !important;
  background: #fff;
  color: #047ED6;
  min-width: 88px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #047ED6;
}

nav#top-nav-header ul.get-started-links li a.Login-button.login:hover {
  background: #047ED6;
  color: #fff;
}

.header-new {
  position: relative;
  z-index: 999;
}

.header-new a.navbar-brand {
  padding: 0;
  margin-top: -3px;
}

.header-new .navbar-brand img {
  filter: brightness(0) invert(1);
}

#top-nav-header ul.get-started-links .nav-item-clr a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 0.3s;
}

#top-nav-header ul.get-started-links .nav-item-clr a:hover {
  color: #2EBC00;
}

/*  */
.create-password-cstm>i {
  position: absolute;
  right: 26px;
  top: 40px;
  font-size: 18px;
  color: #333333;
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid #E1E6EF !important;
}


/* 2 wizard */

.community-url-field .cstm-url-field {
  position: absolute;
  right: 23px;
  top: 43px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #3F7CEA;
}

.custom-signup-form .text-end .previous.btn.previous-button-border {
  max-width: 140px;
  width: 100%;
  border: 1px solid #7D8490;
  background-color: transparent;
  border-radius: 6px !important;
  margin-right: 10px;
  font-size: 16px;
  color: #7D8490;
  text-transform: uppercase;
  font-weight: 600;
  padding: .5rem .75rem;
}
.custom-signup-form .text-end .previous.btn.previous-button-border:hover {
  background-color: #7D8490;
  color: #fff;
}

.custom-signup-form .text-end .next.btn.next-button:hover {
  background-color: #fff;
  color: #047ED6;
  border: 1px solid #047ED6;
}

.plan-card-header {
  width: 100%;
}

.community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label {
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  top: -4px;
}

.community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label .price-text {
  font-weight: 600;
  font-size: 20px;
  top: 8px;
  color: #333333;
}
.community-plan-accordion .card .card-header .custom-control .plan-card-header .custom-control-label .price-text small {
  font-size: 14px;
}

.community-plan-accordion .card .card-header {
  padding: 16px;
  border-radius: 6px;
  border-bottom: 0;
  background-color: transparent;
  cursor: pointer;
}

.community-plan-accordion .card {
  /* background-image: linear-gradient( to right, rgb(123, 67, 151), rgb(255 111 50) ); */
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  border: 1px solid transparent;
  background-color: transparent;
  /* border-radius: 20px; */
  border-radius: 6px;
  background-color: #ffff;
  position: relative;
  z-index: 1;
  border: 1px solid #E1E6EF;
}

.community-plan-accordion .card .collapse.show::after {
  visibility: visible;
  opacity: 1;
}

.community-plan-accordion .card .collapse.show .custom-control-label .price-text {
  color: #047ED6;
}

.community-plan-accordion .card .collapse::after {
  content: "";
  border: 1px solid #047ED6;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 6px;
  visibility: hidden;
  /* opacity: 0; */
  transition: 0.3s ease-in-out;
}

.community-plan-accordion .card .card-body {
  color: #fff;
  border-radius: 0 0 10px 10px;
  padding-top: 0.25rem;
}

.community-plan-accordion .card .card-body .dots {
  font-size: 13px;
  margin: 0;
  color: #545454;
  margin-bottom: 3px;
}

.community-plan-accordion .card .card-body .dots:after {
  width: 5px;
  height: 5px;
  background-color: #3F7CEA;
}

/* .community-plan-accordion .card .card-header .custom-control .plan-card-header .form-check-input:not(.collapsed) .price-text {
  color: aqua;
} */

/* 3 vizard */

.plan-payment-body {
  padding-top: 20px;
}

.plan-payment-images img {
  width: 50px !important;
  margin-right: 10px;
  cursor: pointer;
}

.get-started-bg-new {
  min-Height: 90vh;
  position: relative;
  z-index: 999;
  margin-top: 60px;
}

.bg_header_new_homepge-new .get-started-bg.custom_bg_homepage h3.get-started-prg small {
  display: block;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  max-width: auto;
}
.bg_header_new_homepge-new .get-started-bg.custom_bg_homepage h3.get-started-prg {
  font-size: 44px;
  font-weight: 600;
}
.bg_header_new_homepge-new .get-started-bg.custom_bg_homepage h3.get-started-prg span {
  text-shadow: none;
}
.bg_header_new_homepge-new .get-started-bg.custom_bg_homepage p {
  font-weight: 400;
  max-width: 100%;
  line-height: 27px;
}
.bg_header_new_homepge-new .main-heading {
  font-size: 26px;
  font-weight: 500;
  margin: 0 0 20px;
}
.bg_header_new_homepge-new #msform {
  margin-top: 0;
}
.bg_header_new_homepge-new .get-started-bg.custom_bg_homepage {
  min-height: calc(100vh - 88px);
}
.community-url-field .cstm-field {
  padding-right: 6.5rem;
  text-overflow: ellipsis;
  width: 100%;
}
.bg_header_new_homepge-new .field-set-form {
  margin-bottom: 20px;
}
.bg_header_new_homepge-new .border-top {
  border-top: 1px solid #E1E6EF !important;
}
.signup-payment-page .col-md-6 {
  width: 100%;
}
.signup-payment-page .plan-payment-body {
  padding-top: 0;
}
.signup-payment-page .payment-page,
.signup-payment-page .dashboard-outer-container {
  padding: 0;
}
.signup-payment-page .plan-payment-form .btn-primary-theme.btn-outline {
  background: #047ED6;
  font-size: 18px;
  border-radius: 6px !important;
  color: #fff;
  text-transform: uppercase;
  max-width: 150px;
  width: 100%;
  font-weight: 600;
}
.signup-payment-page .plan-payment-form .btn-primary-theme.btn-outline:hover,
.signup-payment-page .plan-payment-form .btn-primary-theme.btn-outline:focus {
  background-color: #fff !important;
  color: #047ED6 !important;
}
.signup-payment-page .get-started-bg-new {
  min-height: auto;
  margin-top: 0;
}
.h-150 {
  height: 150px !important;
} 
.font-size-26 {
  font-size: 26px;
}
.signup-payment-page .cong-heading {
  font-size: 32px;
}
.signup-payment-page .congratulation-box h3 {
  font-size: 26px;
}
.custom-signup-form .get-started-bg-new {
  min-height: auto;
  margin-top: 0;
}
.custom-signup-form .congratulation-box .cong-heading {
  font-size: 32px;
}
.custom-signup-form .congratulation-box h3 {
  font-size: 24px;
}